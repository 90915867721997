import React, {useEffect, useState} from "react";
import {Route, Routes} from "react-router-dom";
import {dashbordMenu, landingMenu} from './routerMenu.js';

import CreateSitePages from "../pages/site_pages/create";
import EditSitePages from "../pages/site_pages/edit";

import CreateHeader from "../pages/header/create";
import EditHeader from "../pages/header/edit";

import CreateFooter from "../pages/footer/create";
import EditFooter from "../pages/footer/edit";

import CreateSidebar from "../pages/sidebar/create";
import EditSidebar from "../pages/sidebar/edit";

import {getUrl} from "../service";
import CategoryList from "../pages/site_section/categoryList";
import PostingList from "../pages/site_section/postingList";
import CreateSectionCategory from "../pages/site_section/categoryCreate";
import EditSectionCategory from "../pages/site_section/categoryEdit";
import CreatePosting from "../pages/site_section/postingCreate";
import EditPosting from "../pages/site_section/postingEdit";
import {GetSectionCategory, GetSectionPosting} from "../pages/api";
import WebsiteClientV4 from "../pages/websites/web_v4";
import WebsiteClient from "../pages/websites/web_v3";

function Index() {
    const [urlList, setUrlList] = useState(false);

    const dispatchURL = () => {
        getUrl().then(async (response) => {
            await setUrlList(response)
        });
    };

    useEffect(() => {
        dispatchURL();
    }, []);

    // Router settings
    return (
        <div className="router">
            <Routes>

                {/*Pages Not Found*/}
                {/*<Route path="*" key="special-1" element={<PathNotResolved/>}/>*/}

                {/*Default Page*/}
                <Route path="/" key="special-2" element={<WebsiteClient/>}/>

                {/* Website */}
                {urlList && urlList.map((urlData, index) => {
                    return (
                        <Route index key={"web-"+index} path={urlData.path} element={<WebsiteClient/>}/>
                    )
                })}

                {/* landing */}
                {landingMenu.map((menuData, index) => {
                    return (
                        <Route index key={index} path={menuData.path} element={menuData.components}/>
                    )
                })}

                {/* dashboard */}
                {dashbordMenu.map((menuData, index) => {
                    if (menuData.type === "sub") {

                        return (
                            <>
                                {
                                    menuData.sub.map((subMenu, subIndex) => (
                                        <Route index key={'sub-' + subIndex} path={subMenu.path}
                                               element={subMenu.components}/>
                                    ))
                                }
                            </>

                        )

                    } else {
                        return (
                            <Route index key={index} path={menuData.path} element={menuData.components}/>
                        )
                    }

                })}

                {/*Site Pages*/}
                <Route path="/admin/sitepage-create" key="special-3" element={<CreateSitePages/>}/>
                <Route path="/admin/sitepage-edit/:infoID" key="special-4" element={<EditSitePages/>}/>

                {/*Header*/}
                <Route path="/admin/header-create" key="special-5" element={<CreateHeader/>}/>
                <Route path="/admin/header-edit/:infoID" key="special-6" element={<EditHeader/>}/>

                {/*Footer*/}
                <Route path="/admin/footer-create" key="special-7" element={<CreateFooter/>}/>
                <Route path="/admin/footer-edit/:infoID" key="special-8" element={<EditFooter/>}/>

                {/*Sidebar*/}
                <Route path="/admin/sidebar-create" key="special-9" element={<CreateSidebar/>}/>
                <Route path="/admin/sidebar-edit/:infoID" key="special-10" element={<EditSidebar/>}/>

                {/*Section*/}
                <Route path="/admin/categories/:infoID" key="special-11" element={<CategoryList/>}/>
                <Route path="/admin/addCategory/:type/:infoID" key="special-12" element={<CreateSectionCategory/>}/>
                <Route path="/admin/editCategory/:infoID" key="special-13" element={<EditSectionCategory/>}/>
                <Route path="/admin/posting/:infoID" key="special-14" element={<PostingList/>}/>
                <Route path="/admin/postingCreate/:infoID" key="special-15" element={<CreatePosting/>}/>
                <Route path="/admin/postingEdit/:infoID" key="special-16" element={<EditPosting/>}/>

                <Route path="/api/getSectionCategory/:sectionSlug" key="special-17" element={<GetSectionCategory/>}/>
                <Route path="/api/getSectionPosting/:sectionSlug/:categorySlug" key="special-18" element={<GetSectionPosting/>}/>
                <Route path="/api/getSectionPosting/:sectionSlug" key="special-19" element={<GetSectionPosting/>}/>

            </Routes>
        </div>
    );
};

export default Index;
